import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import AniLinkWrapper from "../components/aniLinkWrapper"
const ProductsPage = ({ data }) => {
  useEffect(() => {
    // redirect
    window.location.href = "//buffy.co/collections/all-products"
  }, [])

  return (
    <>
      <h1>Try for Free</h1>
      <div className="products">
        {data.allShopifyProduct.edges.map(({ node }) => {
          const { images } = node

          return (
            <AniLinkWrapper
              to={`/product/${node.handle}`}
              key={`link-${node.shopifyId}`}
            >
              <div key={node.shopifyId}>
                {images && images.length && images[0].localFile ? (
                  <Img sizes={images[0].localFile.childImageSharp.sizes} />
                ) : null}
                <h3>{node.title}</h3>
                <p>from ${node.priceRange.minVariantPrice.amount}</p>
                <p>{node.description}</p>
              </div>
            </AniLinkWrapper>
          )
        })}
      </div>
    </>
  )
}
export default ProductsPage
export const query = graphql`
  {
    allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          availableForSale
          description
          handle
          images {
            localFile {
              childImageSharp {
                sizes {
                  sizes
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  srcSet
                  src
                  presentationWidth
                  presentationHeight
                  originalName
                  originalImg
                  base64
                  aspectRatio
                }
              }
            }
          }
          priceRange {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          shopifyId
          title
        }
      }
    }
  }
`
