import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const defaultDuration = 0.2
const AniLinkWrapper = ({ children, to, duration, type }) => {
  const settings = {
    to: to || "/",
    duration: duration || defaultDuration,
  }
  if (type) {
    settings[type] = true
  } else {
    settings["fade"] = true
  }
  return <AniLink {...settings}>{children}</AniLink>
}

export default AniLinkWrapper
